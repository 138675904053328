import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

const BlogContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  padding-top: 5rem;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1.25rem;
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.8;
  color: var(--light-slate);
`;

const Quote = styled.blockquote`
  font-size: 1.5rem;
  margin: 40px auto;
  padding: 20px;
  border-left: 5px solid var(--green);
  background-color: var(--light-navy);
  color: var(--lightest-slate);
  text-align: left;

  footer {
    margin-top: 10px;
    font-size: 1rem;
    color: var(--light-slate);
    font-style: italic;
    text-align: right;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  font-size: 1.25rem;
  padding: 10px 20px;
  background-color: var(--green);
  color: var(--navy);
  border: none;
  cursor: pointer;
  border-radius: 30px;
  margin: 0 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--light-slate);
  }
`;

const Blog = () => {
  const [activeTab, setActiveTab] = useState('quotes');

  return (
    <Layout>
      <Helmet>
        <title>Blog | My Website</title>
      </Helmet>
      <BlogContainer>
        <Title>My Blog Post</Title>
        <TabContainer>
          <Tab onClick={() => setActiveTab('quotes')}>Quotes</Tab>
          <Tab onClick={() => setActiveTab('blog')}>Blog</Tab>
        </TabContainer>

        {activeTab === 'quotes' && (
          <div>
            <Text>
              Welcome to my blog! Here you'll find my thoughts on various topics.
            </Text>
            <Quote>
              "The only way to do great work is to love what you do."
              <footer>— Steve Jobs</footer>
            </Quote>
            <Quote>
              "While we can grasp things intellectually, true understanding comes from the heart."
              <footer>— Me</footer>
            </Quote>
            <Quote>
              "Life is really simple, but we insist on making it complicated.”
              <footer>— Confucius</footer>
            </Quote>
            <Quote>
              “I don’t care what you think about me. I don’t think about you at all.”
              <footer>— Coco Chanel</footer>
            </Quote>
            <Quote>
              "When we dwell on the past, we live with bitterness and regret. When we try to predict the future, we live with anxiety and uncertain hope. The only way to live happily is to embrace the present."
              <footer>— Me</footer>
            </Quote>
            <Quote>
              "In the end, it's not the years in your life that count. It's the life in your years."
              <footer>— Abraham Lincoln</footer>
            </Quote>
            <Quote>
              "Everything that was meant to happen, happens."
              <footer>— Me</footer>
            </Quote>
            <Quote>
              “An unexamined life is not worth living.”
              <footer>— Socrates</footer>
            </Quote>
            <Quote>
              “Life is either a daring adventure or nothing.”
              <footer>— Helen Keller</footer>
            </Quote>
            <Quote>
              “You become what you believe.”
              <footer>— Oprah Winfrey</footer>
            </Quote>
            <Quote>
              “If you are not willing to risk the usual, you will have to settle for the ordinary.”
              <footer>— Jim Rohn</footer>
            </Quote>
          </div>
        )}

        {activeTab === 'blog' && (
          <div>
            <Text>
              Integrity and honesty are fundamental values that guide our actions daily. They are crucial not only in major decisions but also in small, everyday acts. In 2024, I learned a valuable lesson: there will always be people who criticize and judge you without understanding your situation. It's essential to stay true to your principles and vision.
            </Text>
            <Text>
              Only you know what you truly deserve and your long-term goals. Don't let negative opinions deter you. Believe in yourself and your dreams. Your determination and honesty will take you far.
            </Text>
            <Text>
              The most impressive people care about others' opinions but on a long time horizon. They value being right in the end, even if misunderstood in the moment. This perspective gives them the space to solve important problems that others might ignore. As long as you stay true to yourself, being misunderstood is a strength, not a weakness.
            </Text>
            <Text>
              18/05/2024
            </Text>
          </div>
        )}
      </BlogContainer>
    </Layout>
  );
};

export default Blog;
